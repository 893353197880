import React from 'react';
import AboutUsBanner from '../../components/aboutus-banner/AboutUsBanner';
import {HrCandidate,Teamwork} from '../../components/svg/Icon';
import Footer from './../../components/footer/Footer'

function AboutUs() {
  return (
    <>
    <div className='pad-t-70 box-115'>
      <div className='container-fluid'>
        <div className='row'>
          <div className="col-xl-9">
          <div className='pad-t-70'>
          <h5 className="card-title">About us </h5>
          <div className='pad-t-15'>
            <p className="card-text">
            Connect Nex is a cutting-edge job portal designed to bridge the gap
between talented job seekers and forward-thinking employers. Our
platform is built on the principles of innovation, efficiency, and
inclusivity, providing a seamless experience for users at all stages of
their career journey. With a commitment to leveraging technology, we
aim to streamline the hiring process and empower individuals to find
fulfilling employment opportunities.​
               </p>
         </div>
          </div>
          </div>
          <div className="col-xl-3 d-none d-xl-block">
          <div className='svg'><HrCandidate/></div> 
          </div>
        </div>

        <div className='row'>
          <div className="col-xl-3 d-none d-xl-block">
          <div className='svg'><Teamwork/></div> 
          </div>
          <div className="col-xl-9">
          <div className='pad-t-70'>
          <h5 className="card-title">Vision </h5>
          <div className='pad-t-15'>
            <p className="card-text"> 
            Connect Nex should be the leading job portal that transforms the
hiring landscape, fostering a world where every job seeker can
discover their ideal career path and every employer can connect with
exceptional talent, driving mutual growth and success.
            </p>
         </div>
         <br></br>
          <h5 className="card-title">Mission </h5>
          <div className='pad-t-15'>
            <p className="card-text"> 
            Our mission is to bridge the gap between job seekers and employers,
making the hiring process efficient and to simplify the job search and
recruitment process, ensuring that every individual can find their ideal
career path and every employer can discover the talent they need to
succeed accessible for everyone
            </p>
         </div>
          </div>
          </div>
        </div>
        <div className='row'>
        <div className="col-xl-6">
          <div className='pad-t-70'>
          <h5 className="card-title">Support Employers </h5>
          <div className='pad-t-15'>
            <p className="card-text">
            Offer innovative solutions to attract, engage,
            and retain top talent while streamlining the recruitment process.Encourage job 
            seekers to join local or online communities 
            where they can share resources, exchange tips, and provide moral support.
               </p>
         </div>
          </div>
          </div>
          
        <div className="col-xl-6">
          <div className='pad-t-70'>
          <h5 className="card-title">Empower Job Seekers </h5>
          <div className='pad-t-15'>
            <p className="card-text">
            Provide tools and resources that enable
individuals to explore career opportunities, enhance their skills, and
connect with potential employers.Our goal is to offer valuable resources, guidance, 
and motivation that help them successfully navigate to best suitable job.
               </p>
         </div>
          </div>
          </div>
          </div>
          <div className='row'>
        <div className="col-xl-6">
          <div className='pad-t-70'>
          <h5 className="card-title">Foster Inclusivity</h5>
          <div className='pad-t-15'>
            <p className="card-text">
            Create an inclusive platform that celebrates
diversity and ensures equal access to job opportunities for all
individuals, regardless of their background.
               </p>
         </div>
          </div>
          </div>
          
        <div className="col-xl-6">
          <div className='pad-t-70'>
          <h5 className="card-title">Leverage Technology: </h5>
          <div className='pad-t-15'>
            <p className="card-text">
            Utilize advanced technology and data-driven
insights to improve the job search and hiring experience for both job
seekers and employers.
               </p>
         </div>
          </div>
          </div>
          </div>
      </div>
    </div>
    
    </>

  )
}

export default AboutUs