import React, { useState } from 'react'
import image from '../assets/images/why-us.png';
import JobSearch from '../components/jobSearch/JobSearch';
import CardCategoryLayout from './cardCategoryLayout/CardCategoryLayout';
import SearchBanner from '../components/searchBanner/SearchBanner';
import AboutUsBanner from '../components/aboutus-banner/AboutUsBanner';
import { Link } from 'react-router-dom';
import CardCarousel from '../components/carousel/CardCarousel';
import CardCategory from '../components/categoryCard/CardCategory';
import Footer from '../components/footer/Footer';
import { ReactComponent as YourSvg } from '../assets/SVG-PL/java-logo-col.svg';
import { ReactComponent as Python } from '../assets/SVG-PL/python-col.svg';
import { ReactComponent as Php } from '../assets/SVG-PL/php-svgrepo-com.svg';
import { ReactComponent as Javascript } from '../assets/SVG-PL/javascript-svgrepo-com.svg';
import { ReactComponent as ReactLogo } from '../assets/SVG-PL/react-svgrepo-com.svg';
import { ReactComponent as Angular } from '../assets/SVG-PL/angular-svgrepo-com.svg';
import { ReactComponent as Dotnet } from '../assets/SVG-PL/dotnet.svg';
import { ReactComponent as Linux } from '../assets/SVG-PL/linux-svgrepo-com.svg';
import { ReactComponent as Ubuntu } from '../assets/SVG-PL/ubuntu.svg';
import { ReactComponent as Zend } from '../assets/SVG-PL/zend.svg';
import { ReactComponent as Unitysmall } from '../assets/SVG-PL/unity_small.svg';
import { ReactComponent as Wordpress } from '../assets/SVG-PL/wordpress-color-svgrepo-com.svg';
import { ReactComponent as Swift } from '../assets/SVG-PL/swift-col.svg';
import ApplyJobPopUp from '../components/applyJobPopUp/ApplyJobPopUp';

export default function MainContentLayout() {
  const [showLoginModal, setShowLoginModal] = useState(false);
  return (
    <>
      <div>


        <div className='pxp-hero vh-100'>
          <div className='pxp-hero-caption'>
            <div className='container-fluid'>
              <div className='row'>
                <div className="col-md-6  col-sm-12">
                  <div className='solar-space'>
                    <JobSearch />
                    <div className="input-wrapper pad-t-15">
                      <Link to='/jobs' className='btn-style primary-bg-color remove-underline'>Discover Jobs</Link>
                      <button className='btn-style primary-bg-color' onClick={() => setShowLoginModal(true)}>Submit your Resume</button>
                      <ApplyJobPopUp
                        show={showLoginModal}
                        setShowLoginModal={setShowLoginModal}
                        onHide={() => setShowLoginModal(false)}
                        jobidProp={null}
                      />
                    </div>
                  </div>
                  
                  {
                    /*
 <div className="input-wrapper pad-t-15">
                    <div className="p-2"> <YourSvg /></div>
                    <div className="p-2">  <Python /></div>
                    <div className="p-2">
                      < Swift />
                    </div>
                    <div className="p-2">
                      < Wordpress />
                    </div>
                    <div className="p-2">
                      < Angular />
                    </div>
                    <div className="p-2">
                      < Javascript />
                    </div>
                  </div>
                   <div className="input-wrapper pad-t-15">
                    <div className="p-2">
                      <Linux />
                    </div>
                    <div className="p-2">
                      <ReactLogo />
                    </div>
                    <div className="p-2">
                      <Php />
                    </div>
                  </div>
                    */
                  }
                 
                 
                </div>
                <div className="col-xl-6 col-md-6 d-none d-lg-block" >
                  <div className='solar-space'>
                    <img className='image-style ' src={image} />
                  </div>
                  <div className="pxp-hero-right-bg-card pxp-has-animation pxp-animate"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-category-box'>
        <CardCategoryLayout />
      </div>
      <AboutUsBanner />
      
    </>
  )
}
