import React,{useEffect,useState} from 'react'
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useLazyGetJobsByFilterQuery } from '../../rtk/jobs';
import {useNavigate} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import JSpinner from '../../components/jspinner/JSpinner';
import SideBarJobsSkeleton from './skeleton/SideBarJobsSkeleton';

function SideBarJobs(props) {
  const navigate = useNavigate();
const {dataObj}= props;


const [skillsState, setSkillsState] = useState([]);
const [jobTypeState, setJobTypeState] = useState([]);
const [trigger, result,{ 
  loading: prodLoading,
  error: prodError, 
  data: prodData 
  }] = useLazyGetJobsByFilterQuery(skillsState,jobTypeState);


const redictOnClick = (e) => {
  
  navigate(e)
 
}

useEffect(() => {
 let jobTypeArr = []
dataObj.jobtype.map((item) => {
  jobTypeArr.push(item.jobType);
})
 
let skillArr = []
dataObj.skills.map((item) => {
  skillArr.push(item.skillName);
})
//setSkillsState(skillArr);
//setJobTypeState(jobTypeArr);
let trigerData = trigger({  jobType : jobTypeArr.toString(), skills: skillArr.toString() });
}, [])

  return (
    <div className='card-body'>

       
{
        result.isError ? (
          <div className='pad-t-30'> Oh no, there was an error</div>
      ) : result.isLoading ? (
        <> <SideBarJobsSkeleton/>
        <SideBarJobsSkeleton/>
        <SideBarJobsSkeleton/>
        <SideBarJobsSkeleton/>
        </>
      ) : result.isSuccess  ? (
        <>
       
        {
      result.data.data.map((item, i, arr) => 
      
      <div className='card-body'>
        <p className='title'>
        <a href={"/job/"+item.jobTitle.replace(/ /g,"-").replace('/',"-").replace('//',"-")+"?id="+item.jobId} >{item.jobTitle}</a >
        </p>
        <p className='sub-header'>{item.company}</p>
                    <div className='flex-layout'>
                        <div className=''>
                        <p className='sub-info'> <Icon.BriefcaseFill size={12}/>
                        <span className='pad-l-5'> {item.expRequired.minExp}- {item.expRequired.maxExp} Years</span>
                        </p>
                        </div>
                       
                        <div className='pad-l-10'>
                        <p className='sub-info'> <Icon.GeoAltFill size={12}/>
                        <span className='pad-l-5'>{item.location.city}, {item.location.state},{item.location.country}</span>
                        </p>
                        </div>
                    
                    </div>
                    </div>
      )

}
                    </>
      ) : null
      }
        

    </div>
  )
}

export default SideBarJobs